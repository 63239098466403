<template lang="pug">
modal(size='base' @close='$emit("close")')
  generic-form(:endpoint='`/_/DeclineOfferForm`' :allowShortcuts='true' @submitSuccess='$emit("close")')
    template(#default)
      form-header(class='mb-2')
        | {{ $t('Decline Offer') }}
        template(#below)
          p(class='m-0') {{ $t('inquiry.decline_reason_message') }}
      form-hidden(name='Id' :modelValue='id')
      form-textarea(name='DeclineReason')
      form-footer
        form-action(type='secondary' :primary='true' name='SendDecline')
</template>

<script lang="ts">
import { defineComponent } from 'vue'

const FormDeclineOffer = defineComponent({
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
  },
})
export default FormDeclineOffer
</script>
